import type { CloudId, CloudName } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Environment } from '@atlassian/jira-shared-types/src/tenant-context.tsx';

// MR - stands for the atlassian monorepo prefix
const isOverridingFragments = (): boolean =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	!!(window?.BUILD_KEY && !/^(?:MR)?JF-PROD-\d+$/.test(window.BUILD_KEY));
const isOverridingSsr = (): boolean =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	!!(window?.SSR_BUILD_KEY && !/^(?:MR)?JF-PROD-\d+$/i.test(window.SSR_BUILD_KEY));
const isVoltExplore = ({
	cloudId,
	cloudName,
}: {
	cloudId: CloudId;
	cloudName: CloudName;
}): boolean => /^(TEST--)/.test(cloudId) && /^(atl-vertigo--jira-jira-branch)/.test(cloudName);
/**
 * Selects the 'staging' environment under specific conditions, defaulting to 'dev' otherwise.
 * This decision is based on the current environment setting and the absence of specific overrides.
 */
const getStagingEnvironment = ({
	environment,
	cloudId,
	cloudName,
}: {
	cloudId: CloudId;
	cloudName: CloudName;
	environment: Environment | null;
}): string => {
	if (
		environment === 'staging' &&
		!isOverridingFragments() &&
		!isOverridingSsr() &&
		!isVoltExplore({
			cloudId,
			cloudName,
		})
	) {
		return environment;
	}
	return 'dev';
};
export default getStagingEnvironment;
